import React from 'react'

import Layout from "../components/Layout"
import NavigationPages from "../components/NavigationPages"
import EmployeeContent from "../components/ForEmployees"
import SEO from "../components/seo"

import { ThemeProvider } from 'styled-components'
import General from '../styles/theme'

const Employees = () => (
  <ThemeProvider theme={General}>
    <Layout>
    <SEO title="For Employees" />
    <NavigationPages />
    <EmployeeContent/>
    </Layout>
  </ThemeProvider>
)

export default Employees