import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"

const Employee = ({ t, i18n }) => (
  <>
{/*     <S.WrapperTitleSolutions>
      <S.TitleSolutions>
      {t("forEmployees.titleOne")}<hr />
      </S.TitleSolutions>
    </S.WrapperTitleSolutions> */}

    <S.Content>
      <S.LeftCards>
        <S.Card color="#7AD1A6">
          <S.Title>{t("forEmployees.titleTwo")}</S.Title>
          <S.Description>{t("forEmployees.titleThree")}</S.Description>
        </S.Card>
      </S.LeftCards>

      <S.RightCards>
        <S.Card color="#AA78ED">
          <S.Title>{t("forEmployees.titleFour")}</S.Title>
          <S.Description>{t("forEmployees.titleFive")}</S.Description>
        </S.Card>
      </S.RightCards>
    </S.Content>

    <S.Content>
      <S.LeftCards>
        <S.Card color="#ED7878">
          <S.Title>{t("forEmployees.titleSix")}</S.Title>
          <S.Description>{t("forEmployees.titleSeven")}</S.Description>
        </S.Card>
      </S.LeftCards>

      <S.RightCards>
        <S.Card color="#EDED78">
          <S.Title>{t("forEmployees.titleEight")}</S.Title>
          <S.Description>{t("forEmployees.titleNine")}</S.Description>
        </S.Card>
      </S.RightCards>
    </S.Content>

    <S.Content>
      <S.LeftCards>
        <S.Card color="#78C9ED">
          <S.Title>{t("forEmployees.titleTen")}</S.Title>
          <S.Description>{t("forEmployees.titleEleven")}</S.Description>
        </S.Card>
      </S.LeftCards>

      <S.RightCards>
      <S.Card color="#7AD1A6">
        <S.Title>{t("forEmployees.titleOneMore")}</S.Title>
        <S.Description>{t("forEmployees.titleTwoMore")}</S.Description>
      </S.Card>
    </S.RightCards>
    </S.Content>
 
    <S.Content>
      <S.LeftCards>
        <S.Card color="#e165cf">
          <S.Title>
            {t("forEmployees.googleCalendarTitle")}
          </S.Title>
          
          <S.Description>{t("forEmployees.googleCalendarDescription")}</S.Description>
{/*           <S.IconWrapper>
            <Google />
          </S.IconWrapper> */}
        </S.Card>
      </S.LeftCards>
    </S.Content>
  </>
)

export default withTrans(Employee)
